@import '../../scss/breakpoints';

.SearchBar{
  position: absolute;
  padding: .5rem 0;
  z-index: 2;
  background: #181818;
  width: 100%;
  @include media-breakpoint-down(sm) {
    padding: .5rem .3rem;
  }
}
.Content{
  padding: 3rem 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  @include media-breakpoint-down(sm) {
    padding-top: 3rem;
  }
}
