@import '../../scss/breakpoints';

.PlayList{

  .ThumbnailContainer{
    cursor: pointer;
    aspect-ratio: 16/9;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .BackImage{
      border-radius: .5rem;
      width: 95%;
      height: 100%;
      background: #bababa;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Thumbnail {
      border-radius: .5rem;
      width: 100%;
      height: 95%;

      position: absolute;
      cursor: pointer;
      aspect-ratio: 16/9;
      overflow: hidden;
      border-radius: .5rem;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .Cover {
        top: 0;
        position: absolute;
        pointer-events: none;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .Bottom {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .Info {
            display: flex;
            justify-content: flex-end;

            .Count {
              display: flex;
              align-items: center;
              font-size: .75rem;
              font-weight: bold;
              border-radius: .5rem;
              padding: .1rem .4rem;
              background: rgba(0, 0, 0, .5);
              margin: .7rem .5rem;
            }
          }
        }
      }
    }
  }

}
