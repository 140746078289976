@import '../../scss/breakpoints';

.Actor{

  width: 100%;
  height: 100%;
  background: #e0e0e0;
  .TranslateName{
    user-select: all;
  }
  .Name{
    user-select: all;
  }
  .Image{
    cursor: pointer;
    width: 100%;
    img{
      width: 100%;
    }
    .Info{
      position: absolute;
      padding: .5rem;
      top: 0;
      right: 0;
      display: flex;
      font-size: 1rem;
      justify-content: center;
      align-items: center;
      .Dot{
        background: #afafaf;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }
}