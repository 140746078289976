@import '../../scss/breakpoints';

.PlayList{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
  border: 1px solid #efefef;

  &:first-child{
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }
  &:last-child{
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }
  .CheckBox{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-size: 1.5rem;
  }
  .Name{

  }
}