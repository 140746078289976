@import '../../scss/breakpoints';

.DetailView{
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  @include media-breakpoint-down(sm) {
    overflow-y: hidden;
    height: 100%;
  }
  .DetailContainer{
    padding: 0;
    @include media-breakpoint-down(sm){
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  .PlayerContainer{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(sm){
      width: 100%;
    }
  }
  .DetailInfo{
    flex: 1;
    padding: .5rem;
    .ActorList{
      display: flex;
      flex-direction: column;
      padding-top: .5rem;
      .List {
        padding-top: .5rem;
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
        min-width: 0;
        flex: 1 1 0;
      }
    }
  }
  .ListWrap {
    transition: all .5s ease-out;
    &.Open{
      transform: translateY(0%);
    }
    @include media-breakpoint-down(sm){
      transform: translateY(85%);
      position:absolute;
      bottom: 0;
      width: 100%;
    }
    .Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .5rem;
      @include media-breakpoint-down(sm) {
        background: #000;
      }
      .Title {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
      }

      .Link {
        display: none;
        @include media-breakpoint-down(sm){
          display: flex;
        }
        a {
          color: var(--bs-primary);
          cursor: pointer;
          text-decoration: none;
        }
      }
    }

    .List {
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      min-width: 0;
      flex: 1 1 0;
      background: #282828;
    }
  }
}

