@import '../../scss/breakpoints';

.SearchBar{
  position: absolute;
  padding: 0 .5rem .5rem .5rem;
  z-index: 2;
  background: #181818;
  width: 100%;
  @include media-breakpoint-down(sm) {
    padding: 0 .3rem .5rem .3rem;
  }
  .Header{
    width: 100%;
    pointer-events: bounding-box;
    background: #181818;
    justify-content: flex-start;
    display: flex;
    @include media-breakpoint-down(sm) {
      justify-content: space-between;
    }
    .BackButton{
      display: none;
      width: 2rem;
      height: 2rem;
      justify-content: center;
      align-items: center;
      margin: .3rem;
      @include media-breakpoint-down(sm) {
        display: flex;
      }
    }
    .Button{
      width: 2rem;
      height: 2rem;
      justify-content: center;
      align-items: center;
      margin: .3rem;
      cursor: pointer;
    }
    .Title{
      margin: auto 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }


}
.Content{
  padding-top: 5rem;
  padding-bottom: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  @include media-breakpoint-down(sm) {
    padding-top: 6rem;
  }
}

