@import '../../scss/breakpoints';
.MobilePanel{
    display: none;
    overflow-y: hidden;
    overflow-x: auto;
    min-width: 0;
    flex: 1 1 0;
    padding: .5rem;
    @include media-breakpoint-down(sm){
        display: flex;
    }
    .Button{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 .5rem;

        border-radius: 1rem;
        overflow: hidden;
        font-size: .75rem;
        height: 1.5rem;
        margin-right: .3rem;
    }
}

.Player{
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    aspect-ratio: 16/9;
    background: #000;

    &.Cinema{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1050;
    }
    .Buffering{
        pointer-events: none;
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ControlContainer{
        @include media-breakpoint-down(sm){
            display: none;
        }
        pointer-events: bounding-box;
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        align-items: flex-end;
        bottom: 0;
        .Cover{
            top: 0;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            background: #000;
        }
        .Controls{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            position: relative;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAACsCAYAAAC6l8a5AAABB0lEQVQ4T2XI10cFAACF8dvee++67b33uM17b1MkkSSSSBJJJIkkkUQSSSJJ9EfmOw/npfPw8zmBwL/F8MSaOErEQ4JJpEQSJJsUSqRCmkmnRAZkmixKZEOOyaVEHuSbAkoUQpEppkQJlJoySpRDhamkRBVUmxpKBKHW1FGiHhpMIyWaoNm0UKIV2kw7JTqg03RRoht6TC8l+qDfDFBiEIbMMCVGYNSMUWIcJkyIEpMwZaYpMQOzZo4SYYiYKCXmYcEsUmIJlmEFVmEN1mEDNmELtmEHdmEP9uEADuEIjuEETuEMzuECLuEKruEGbuEO7uEBHuEJnuEFXuEN3uEDPuELvuEHfv8A+V4nwdQaFeIAAAAASUVORK5CYII=");
            background-size: contain;
            .Header{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                background: rgba(0,0,0,.2);
                width: 100%;
                padding: .5rem;
                font-weight: 700;
                font-size: .875rem;
            }
            .SeekBarContainer{
                margin: 1rem;
                position: relative;
                &:hover{
                    .SpriteThumbnail{
                        display: block;
                    }
                }
                .SeekBar{
                    width: 100%;

                    display: flex;
                    align-items: center;
                }
                .SpriteThumbnail{
                    display: none;
                    position: absolute;
                    width: 15rem;
                    height: auto;
                    background: #000;
                    bottom: 2rem;
                    transform: translateX(-50%);
                    border: 1px solid #efefef;
                    overflow: hidden;
                    border-radius: 1rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .Option{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-end;
                .LeftControls{
                    display: flex;
                    justify-content: flex-start;
                    .Time{
                        font-size: 1rem;
                        display: flex;
                        color: #fff;
                        justify-content: center;
                        align-items: center;
                        padding-left: 8px;
                        padding-right: 8px;
                    }

                }
                .RightControls{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                }
            }

        }
    }
}

.Button{
    cursor: pointer;
    padding: .3rem .5rem;
}

.Volume{
    display: flex;
    align-items: center;
    width: 120px;
    background: rgba(0,0,0,.3);
    padding: 0 .2rem ;
    border-radius: 1rem;
    .VolumeBarContainer{
        padding-right: .5rem;
        display: flex;
        align-items: center;
        height: 20px;
        width: 100%;
        position: relative;
        -webkit-user-drag: none;
        .VolumeBar {
            height: 13px;
            width: 100%;
            position: absolute;
            display: flex;
            align-items: center;

        }
    }
}