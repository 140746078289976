@import '../../scss/breakpoints';
.SideBar{
  display: flex;
  flex-direction: column;
  width: 4rem;
  border-right: 1px solid #BDBDBD;
  background-color: #131313;
  @include media-breakpoint-down(sm){
    display: none;
  }
  .Menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .75rem;
    color: #fff;
    border: 0;
    border-radius: 0;
    padding: .5rem;
    white-space: pre-wrap;
  }
}


