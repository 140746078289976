@import '../../scss/breakpoints';

.Content{
  padding: 1rem;
  .ListWrap{
    .Header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;
      .Title{
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: bold;
      }
      .Link{
        a{
          color: var(--bs-primary);
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
    .List{
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      min-width: 0;
      flex: 1 1 0;
      padding: .5rem;
      min-height: 225px;
    }
  }
}

.PlayListAddButton{
  aspect-ratio: 1/1;
  min-width: 150px;
  max-width: 150px;
  border: 3px dashed #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: 700;
  cursor: pointer;
}
