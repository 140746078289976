@import '../../scss/breakpoints';

.Content{
  border: 1px solid #282828;
  border-radius: .5rem;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  overflow: hidden;
  .Loader{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
  }
  .List{
    display: flex;
    overflow-y: auto;
    width: 648px;
    .Item{
      position: absolute;
      height: 180px;
      width: 320px;
      margin: auto;
      img{
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }
  .Detail{
    display: flex;
    flex-direction: column;
    flex: 1;
    .Image{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .Buttons{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
